<template>
  <div id="notice">
    <el-dialog
      :title="noticeFormTitle"
      width="1200px"
      :visible.sync="noticeDialogVisible"
      :close-on-click-modal="false"
      @close="noticeDialogClose"
    >
      <el-form
        ref="noticeFormRef"
        :model="noticeForm"
        :rules="noticeFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="noticeNo">
              <el-input
                v-model="noticeForm.noticeNo"
                placeholder="请输入编号"
                clearable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="noticeForm.customerId"
                placeholder="请选择客户"
                clearable
                filterable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
                @change="customerChange"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详细地址" prop="detailedAddress">
              <!-- <el-input
                v-model="noticeForm.detailedAddress"
                placeholder="请输入详细地址"
                clearable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              /> -->
              <el-select
                v-model="noticeForm.detailedAddress"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请选择收货地址"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="contacts">
              <el-input
                v-model="noticeForm.contacts"
                placeholder="请输入联系人"
                clearable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="contactNumber">
              <el-input
                v-model="noticeForm.contactNumber"
                placeholder="请输入联系电话"
                clearable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="新客户" prop="isNew">
              <el-radio-group
                v-model="noticeForm.isNew"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              >
                <el-radio :label="0">
                  否
                </el-radio>
                <el-radio :label="1">
                  是
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="付款方式" prop="paymentMethod">
              <el-radio-group
                v-model="noticeForm.paymentMethod"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
                @change="paymentMethodChange"
              >
                <el-radio :label="1">
                  款到发货
                </el-radio>
                <el-radio :label="2">
                  临时欠款
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款期限" prop="paymentDate">
              <el-date-picker
                v-model="noticeForm.paymentDate"
                placeholder="请选择付款期限"
                value-format="yyyy-MM-dd"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订货日期" prop="orderDate">
              <el-date-picker
                v-model="noticeForm.orderDate"
                placeholder="请选择订货日期"
                value-format="yyyy-MM-dd"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="
              noticeFormTitle === '新增销货通知单' ||
                noticeFormTitle === '修改销货通知单'
            "
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column field="productNo" title="产品编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择产品"
                  clearable
                  filterable
                  @change="materialProductStockChange(row)"
                >
                  <el-option
                    v-for="item in materialProductStockList"
                    :key="item.id"
                    :label="item.productNo"
                    :value="item.productNo"
                  />
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="productName"
              title="产品名称"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="spec"
              title="型号规格"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="stock"
              title="库存数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="unit"
              title="单位"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="quantityBatch"
              title="数量(批次)"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="
                noticeFormTitle === '新增销货通知单' ||
                  noticeFormTitle === '修改销货通知单'
              "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="紧急情况" prop="emergency">
              <el-radio-group
                v-model="noticeForm.emergency"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              >
                <el-radio :label="1">
                  KP
                </el-radio>
                <el-radio :label="2">
                  TK
                </el-radio>
                <el-radio :label="3">
                  CK
                </el-radio>
                <el-radio :label="4">
                  CW
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发货期限" prop="deliveryDeadline">
              <el-date-picker
                v-model="noticeForm.deliveryDeadline"
                placeholder="请选择发货期限"
                value-format="yyyy-MM-dd"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" style="height: 62.8px">
            <el-form-item label="运输方式" prop="transportMode">
              <el-radio-group
                v-model="noticeForm.transportMode"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              >
                <el-radio :label="1">
                  货运
                </el-radio>
                <el-radio :label="2">
                  快递
                </el-radio>
                <el-radio :label="3">
                  其它
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其它运输方式" prop="transportModeOther">
              <el-input
                v-model="noticeForm.transportModeOther"
                placeholder="请输入其它运输方式"
                clearable
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="noticeForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                :disabled="
                  noticeFormTitle !== '新增销货通知单' &&
                    noticeFormTitle !== '修改销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="审核结果" prop="reviewerResult">
              <el-radio-group
                v-model="noticeForm.reviewerResult"
                :disabled="
                  noticeFormTitle !== '审核销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批准结果" prop="approverResult">
              <el-radio-group
                v-model="noticeForm.approverResult"
                :disabled="
                  noticeFormTitle !== '批准销货通知单' &&
                    noticeFormTitle !== '销货通知单详情'
                "
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="noticeDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="noticeFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input
          v-model="searchForm.noticeNo"
          placeholder="请输入编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input
          v-model="searchForm.customerName"
          placeholder="请输入客户名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.status" placeholder="请选择">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['XS_NOTICE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
        <el-button type="primary" icon="el-icon-search" @click="excelExport">
          excel导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="noticePage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
      />
      <el-table-column prop="noticeNo" label="编号" />
      <el-table-column prop="printed" label="打印">
        <template slot-scope="scope">
          <span v-if="scope.row.printed === 1" style="color:red">未打印</span>
          <span v-if="scope.row.printed === 2">已打印</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="detailedAddress" label="详细地址" />
      <el-table-column prop="orderDate" label="订货日期" />
      <el-table-column prop="contacts" label="联系人" />
      <el-table-column prop="contactNumber" label="联系电话" />
      <el-table-column label="新客户">
        <template slot-scope="scope">
          <span v-if="scope.row.isNew === 0">否</span>
          <span v-if="scope.row.isNew === 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="付款方式">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentMethod === 1">款到发货</span>
          <span v-if="scope.row.paymentMethod === 2">临时欠款</span>
        </template>
      </el-table-column>
      <el-table-column label="付款期限">
        <template slot-scope="scope">
          <span v-if="scope.row.paymentDate">{{
            scope.row.paymentDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="紧急情况">
        <template slot-scope="scope">
          <span v-if="scope.row.emergency === 1">KP</span>
          <span v-if="scope.row.emergency === 2">TK</span>
          <span v-if="scope.row.emergency === 3">CK</span>
          <span v-if="scope.row.emergency === 4">CW</span>
        </template>
      </el-table-column>
      <el-table-column label="发货期限">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDeadline">{{
            scope.row.deliveryDeadline.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="运输方式">
        <template slot-scope="scope">
          <span v-if="scope.row.transportMode === 1">货运</span>
          <span v-if="scope.row.transportMode === 2">快递</span>
          <span v-if="scope.row.transportMode === 3">其它</span>
        </template>
      </el-table-column>
      <el-table-column prop="transportModeOther" label="其它运输方式" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editorDate">{{
            scope.row.editorDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{
            scope.row.reviewerDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{
            scope.row.approverDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approverResult === 1">通过</span>
          <span v-if="scope.row.approverResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['XS_NOTICE_DELETE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['XS_NOTICE_UPDATE'])||(scope.row.status === 1)
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['XS_NOTICE_REVIEW']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="
              checkPermission(['XS_NOTICE_APPROVAL']) && scope.row.status === 2
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="scope.row.status === 3"
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="getPrint(scope.$index, scope.row)"
          >
            打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="noticePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addNotice,
  deleteNotice,
  updateNotice,
  selectNoticeInfo,
  selectNoticeList,
  reviewNotice,
  approvalNotice,
  selectCustomerList,
  excelExport
} from '@/api/sale/notice'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'
import moment from 'moment'

export default {
  data () {
    return {
      noticeDialogVisible: false,
      noticeFormTitle: '',
      noticeForm: {
        id: '',
        noticeNo: '',
        customerId: '',
        customerName: '',
        detailedAddress: '',
        contacts: '',
        contactNumber: '',
        isNew: '',
        paymentMethod: '',
        paymentDate: '',
        emergency: '',
        deliveryDeadline: '',
        transportMode: '',
        transportModeOther: '',
        remarks: '',
        reviewerResult: '',
        approverResult: '',
        orderDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        detailJson: '',
        taskId: ''
      },
      noticeFormRules: {
        noticeNo: [
          {
            required: true,
            message: '编号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        customerId: [
          {
            required: true,
            message: '客户名称不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      noticePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        noticeNo: '',
        customerName: '',
        status: ''
      },
      detailList: [],
      customerList: [],
      materialProductStockList: [],
      statusOptions: [{
        value: '1',
        label: '需审核'
      }, {
        value: '2',
        label: '需批准'
      }],
      multipleSelection: [],
      selectedIDs: []
    }
  },
  created () {
    selectNoticeList(this.searchForm).then((res) => {
      this.noticePage = res
    })
    selectCustomerList().then((res) => {
      this.customerList = res
    })
    selectMaterialProductStockList({ type: 1 }).then(res => {
      this.materialProductStockList = res.list
    })
  },
  methods: {
    noticeDialogClose () {
      this.$refs.noticeFormRef.resetFields()
      this.detailList = []
    },
    noticeFormSubmit () {
      if (this.noticeFormTitle === '销货通知单详情') {
        this.noticeDialogVisible = false
        return
      }
      this.$refs.noticeFormRef.validate(async (valid) => {
        this.noticeForm.detailedAddress = this.noticeForm.detailedAddress.toString()
        if (valid) {
          if (this.noticeFormTitle === '新增销货通知单') {
            this.noticeForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addNotice(this.noticeForm)
          } else if (this.noticeFormTitle === '修改销货通知单') {
            this.noticeForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateNotice(this.noticeForm)
          } else if (this.noticeFormTitle === '审核销货通知单') {
            await reviewNotice(this.noticeForm)
          } else if (this.noticeFormTitle === '批准销货通知单') {
            await approvalNotice(this.noticeForm)
          }
          this.noticePage = await selectNoticeList(this.searchForm)
          this.noticeDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.noticeFormTitle = '新增销货通知单'
      this.noticeDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteNotice(row.id)
        if (this.noticePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.noticePage = await selectNoticeList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.noticeFormTitle = '修改销货通知单'
      this.noticeDialogVisible = true
      this.selectNoticeInfoById(row.id)
    },
    handleReview (index, row) {
      this.noticeFormTitle = '审核销货通知单'
      this.noticeDialogVisible = true
      this.selectNoticeInfoById(row.id)
    },
    handleApproval (index, row) {
      this.noticeFormTitle = '批准销货通知单'
      this.noticeDialogVisible = true
      this.selectNoticeInfoById(row.id)
    },
    handleInfo (index, row) {
      this.noticeFormTitle = '销货通知单详情'
      this.noticeDialogVisible = true
      this.selectNoticeInfoById(row.id)
    },
    getPrint (index, row) {
      this.$router.push({ path: '/print2', query: { id: row.id }})
    },
    selectNoticeInfoById (id) {
      selectNoticeInfo(id).then((res) => {
        this.noticeForm.id = res.id
        this.noticeForm.noticeNo = res.noticeNo
        this.noticeForm.customerId = res.customerId
        this.noticeForm.customerName = res.customerName
        this.noticeForm.detailedAddress = res.detailedAddress.split(',')
        this.noticeForm.contacts = res.contacts
        this.noticeForm.contactNumber = res.contactNumber
        this.noticeForm.isNew = res.isNew
        this.noticeForm.paymentMethod = res.paymentMethod
        this.noticeForm.paymentDate = res.paymentDate
        this.noticeForm.emergency = res.emergency
        this.noticeForm.deliveryDeadline = res.deliveryDeadline
        this.noticeForm.transportMode = res.transportMode
        this.noticeForm.transportModeOther = res.transportModeOther
        this.noticeForm.remarks = res.remarks
        this.noticeForm.reviewerResult = res.reviewerResult
        this.noticeForm.approverResult = res.approverResult
        this.noticeForm.taskId = res.taskId
        this.noticeForm.orderDate = res.orderDate
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectNoticeList(this.searchForm).then((res) => {
        this.noticePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectNoticeList(this.searchForm).then((res) => {
        this.noticePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectNoticeList(this.searchForm).then((res) => {
        this.noticePage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['XS_NOTICE_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (
        this.checkPermission(['XS_NOTICE_APPROVAL'])
        && row.status === 2
      ) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    customerChange (value) {
      if (value) {
        let customer = this.customerList.find((item) => item.id === value)
        this.noticeForm.customerName = customer.customerName
        this.noticeForm.detailedAddress = customer.receivingAddress.split(',')
        this.noticeForm.contacts = customer.contactPerson
        this.noticeForm.contactNumber = customer.phone
      } else {
        this.noticeForm.customerName = ''
        this.noticeForm.detailedAddress = ''
        this.noticeForm.contacts = ''
        this.noticeForm.contactNumber = ''
      }
    },
    materialProductStockChange (row) {
      if (row.productNo) {
        let materialProductStock = this.materialProductStockList.find(item => item.productNo === row.productNo)
        row.productName = materialProductStock.productName
        row.spec = materialProductStock.spec
        row.stock = materialProductStock.realStock
        if (materialProductStock.productName.indexOf('中性电极') > 0) {
          row.unit = '片'
        }
        if (materialProductStock.productName.indexOf('手术电极') > 0) {
          row.unit = '支'
        }
      } else {
        row.productName = ''
        row.spec = ''
        row.stock = ''
        row.unit = ''
      }
    },
    paymentMethodChange (val) {
      if (val === 1) {
        // console.log(this.getTime())
        this.noticeForm.paymentDate = moment().format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.noticeForm.paymentDate = ''
      }
    },

    //获取当前日期
    getTime () {
      let dateTime
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let hh = new Date().getHours()
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
        :        new Date().getMinutes()
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds()
        :        new Date().getSeconds()
      dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
      console.log(dateTime)
      return dateTime
    },
    excelExport () {
      if (this.selectedIDs.length > 0) {
        var ids = this.selectedIDs.join(',')
        var url = window.g.BASE_URL + '/sale/notice/export/' + ids　//URL根据自己项目中实际情况而定
        var down = document.createElement('a')
        down.href = url
        // document.body.appendChild(down);　　//此步骤可省略
        down.click()
        down.remove()//此步骤可省略
        this.$refs.multipleTable.clearSelection()
        this.selectedIDs = []
        // excelExport(ids).then((res) => {
        // })
      } else {
        this.$message({
          message: '请选择需要导出的数据！',
          type: 'warning'
        })
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      this.multipleSelection.map((item)=> {
        if (this.selectedIDs.indexOf(item.id) === -1) {
          this.selectedIDs.push(item.id)
        }
      })
      // console.log('多选',this.selectedIDs)
    }
  }
}
</script>

<style>
</style>
